var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("div", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "profile-header" }, [
          _c("div", { staticClass: "row flex-row-reverse" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("ul", { staticClass: "details" }, [
                _c("li", [
                  _c("h6", [_vm._v("Last logged in")]),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(_vm.currentUser.last_login, "h:mm a")
                      )
                    )
                  ]),
                  _c("span", { staticClass: "date" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(_vm.currentUser.last_login, "D/M/YYYY")
                      )
                    )
                  ])
                ]),
                _c("li", [
                  _c("h6", [_vm._v("App version")]),
                  _c("span", { staticClass: "version" }, [_vm._v("V1.0")])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c("h1", { staticClass: "theme-text" }, [_vm._v("My Profile")])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6 column mb-md-0" },
            [
              _vm.profileFormMessage
                ? _c(
                    "div",
                    {
                      staticClass: "alert mb-4",
                      class: _vm.profileFormAlertClass,
                      attrs: { role: "alert" }
                    },
                    [_vm._v(" " + _vm._s(_vm.profileFormMessage) + " ")]
                  )
                : _vm._e(),
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c("div", { staticClass: "form2" }, [
                          _c(
                            "form",
                            {
                              attrs: { action: "#", novalidate: "" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.submitProfileForm($event)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-lg-4" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "first-name-field" } },
                                    [_vm._v("First Name")]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-8" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "First Name",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(v) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.currentUser
                                                          .first_name,
                                                      expression:
                                                        "currentUser.first_name"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid":
                                                      v.invalid && v.validated
                                                  },
                                                  attrs: {
                                                    id: "first-name-field",
                                                    type: "text",
                                                    placeholder: "First Name"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.currentUser.first_name
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.currentUser,
                                                        "first_name",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback text-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.errors[0]) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-lg-4" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "surname-field" } },
                                    [_vm._v("Surname")]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-8" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Surname",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(v) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.currentUser
                                                          .last_name,
                                                      expression:
                                                        "currentUser.last_name"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid":
                                                      v.invalid && v.validated
                                                  },
                                                  attrs: {
                                                    id: "surname-field",
                                                    type: "text",
                                                    placeholder: "Surname"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.currentUser.last_name
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.currentUser,
                                                        "last_name",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback text-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.errors[0]) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-lg-4" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "email-field" } },
                                    [_vm._v("Email Address")]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-8" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "E-mail",
                                        rules: "required|email"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(v) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.currentUser.email,
                                                      expression:
                                                        "currentUser.email"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid":
                                                      v.invalid && v.validated
                                                  },
                                                  attrs: {
                                                    id: "email-field",
                                                    type: "text",
                                                    placeholder: "Email"
                                                  },
                                                  domProps: {
                                                    value: _vm.currentUser.email
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.currentUser,
                                                        "email",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback text-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.errors[0]) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "text-md-right" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "theme-bg btn btn-primary",
                                    attrs: {
                                      type: "submit",
                                      disabled:
                                        invalid || _vm.profileFormLoading,
                                      id: "profile-save-changes-button"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.profileFormLoading,
                                          expression: "profileFormLoading"
                                        }
                                      ],
                                      staticClass:
                                        "spinner-grow spinner-grow-sm",
                                      staticStyle: {
                                        width: "1.0625rem",
                                        height: "1.0625rem"
                                      },
                                      attrs: {
                                        role: "status",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(" Save Changes ")
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("ValidationObserver", {
                ref: "passwordForm",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      var reset = ref.reset
                      return [
                        _c("div", { staticClass: "form2" }, [
                          _c(
                            "form",
                            {
                              attrs: { novalidate: "" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.submitPasswordForm($event)
                                },
                                reset: function($event) {
                                  $event.preventDefault()
                                  return reset($event)
                                }
                              }
                            },
                            [
                              _c("h6", [_vm._v("Password Change")]),
                              _c(
                                "ul",
                                { staticClass: "createria-note list-unstyled" },
                                [
                                  _c("li", [
                                    _vm._v(
                                      "Your password can’t be too similar to your other personal information."
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Your password must contain at least 8 characters."
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Your password can’t be a commonly used password."
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Your password can’t be entirely numeric."
                                    )
                                  ])
                                ]
                              ),
                              _vm.passwordFormMessage
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "alert mb-4",
                                      class: _vm.passwordFormAlertClass,
                                      attrs: { role: "alert" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.passwordFormMessage) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-lg-4" }, [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "current-password-field" }
                                    },
                                    [_vm._v("Current Password")]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-8" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Current Password",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(v) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.passwordFormData
                                                          .current_password,
                                                      expression:
                                                        "passwordFormData.current_password"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid":
                                                      v.invalid && v.validated
                                                  },
                                                  attrs: {
                                                    type: "password",
                                                    id: "current-password-field"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.passwordFormData
                                                        .current_password
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.passwordFormData,
                                                        "current_password",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback text-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.errors[0]) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-lg-4" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "new-password-field" } },
                                    [_vm._v("New Password")]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-8" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "New Password",
                                        rules: "required|confirmed:confirmation"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(v) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.passwordFormData
                                                          .new_password,
                                                      expression:
                                                        "passwordFormData.new_password"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid":
                                                      v.invalid && v.validated
                                                  },
                                                  attrs: {
                                                    type: "password",
                                                    id: "new-password-field"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.passwordFormData
                                                        .new_password
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.passwordFormData,
                                                        "new_password",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback text-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.errors[0]) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-lg-4" }, [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "password-confirmation-field"
                                      }
                                    },
                                    [_vm._v("Confirm Password")]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-8" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Password Confirmation",
                                        rules: "required",
                                        vid: "confirmation"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(v) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.passwordFormData
                                                          .re_new_password,
                                                      expression:
                                                        "passwordFormData.re_new_password"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid":
                                                      v.invalid && v.validated
                                                  },
                                                  attrs: {
                                                    type: "password",
                                                    id:
                                                      "password-confirmation-field"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.passwordFormData
                                                        .re_new_password
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.passwordFormData,
                                                        "re_new_password",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback text-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.errors[0]) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "text-md-right" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "theme-bg btn btn-primary",
                                    attrs: {
                                      type: "submit",
                                      disabled:
                                        invalid || _vm.passwordFormLoading,
                                      id: "profile-update-password-button"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.passwordFormLoading,
                                          expression: "passwordFormLoading"
                                        }
                                      ],
                                      staticClass:
                                        "spinner-grow spinner-grow-sm",
                                      staticStyle: {
                                        width: "1.0625rem",
                                        height: "1.0625rem"
                                      },
                                      attrs: {
                                        role: "status",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(" Update Password ")
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "accordion-wrapper" }, [
              _c("h6", [_vm._v("FAQs")]),
              _c(
                "div",
                { staticClass: "accordion1", attrs: { id: "accordion" } },
                [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading1" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse1",
                              "aria-expanded": "true",
                              "aria-controls": "collapse1"
                            }
                          },
                          [_vm._v(" How do I compare products? ")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse1",
                          "aria-labelledby": "heading1",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " Simply follow the brand selection on the home page to your desired category to open up the list of products available. Tick the ‘compare’ boxes on the products you wish to compare, then open the Compare page on the right hand side. This will then display your compared products. You can then email this list directly to the customer. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading2" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse2",
                              "aria-expanded": "true",
                              "aria-controls": "collapse2"
                            }
                          },
                          [
                            _vm._v(
                              " Can I compare products across multiple brands? "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse2",
                          "aria-labelledby": "heading2",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " Yes, once you follow the brand selection on the home page to your desired category, it will open up a list of available products. At the top of this page, you can select multiple brand options including Bosch, NEFF and Siemens. Ticking these boxes will display all products within your chosen category across your selected brands. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading3" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse3",
                              "aria-expanded": "true",
                              "aria-controls": "collapse3"
                            }
                          },
                          [
                            _vm._v(
                              " Does the platform offer a direct replacement product? "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse3",
                          "aria-labelledby": "heading3",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " No, the platform will not automatically offer a direct replacement product for the customer. It is with your experience and knowledge of the customers’ requirements to use the platform as a tool to offer the best replacement product for them. You will discuss with the customer what is important to them and will select products to suit their needs. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading4" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse4",
                              "aria-expanded": "true",
                              "aria-controls": "collapse4"
                            }
                          },
                          [_vm._v(" What if I can’t find a product? ")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse4",
                          "aria-labelledby": "heading4",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " If a product does not appear, this does not mean it’s not available, it may be that the product has been discontinued, however retailers may still stock it, or that it is simply not stored in the system. If you can’t find a product, please notify the Service Desk and we can take a look at adding it in to the application. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading6" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse6",
                              "aria-expanded": "true",
                              "aria-controls": "collapse6"
                            }
                          },
                          [
                            _vm._v(
                              " How do I use the Quick Search functionality? "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse6",
                          "aria-labelledby": "heading6",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " The Quick Search bar can be used in several ways. If you know a specific ENR number, you can enter this and it will search for the product. Or if you want to quickly search for a specific category, such as ‘Dishwashers’, it will display all products under that category. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading6" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse7",
                              "aria-expanded": "true",
                              "aria-controls": "collapse7"
                            }
                          },
                          [
                            _vm._v(
                              " What do I do if my customer does not receive an email? "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse7",
                          "aria-labelledby": "heading7",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " Ensure the customer allows enough time for the email to come through. Please get the customer to check their junk mail. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading6" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse8",
                              "aria-expanded": "true",
                              "aria-controls": "collapse8"
                            }
                          },
                          [_vm._v(" Who do I contact if I have a problem? ")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse8",
                          "aria-labelledby": "heading8",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " If you are experiencing any issues with the app, please click the ‘Send Help Request’ button in the My Profile section. This will open an email to send to the Service Desk who will be able to assist. Alternatively you can contact them on gb-ieCITSupport@bshg.com or call them directly at 01536436101. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header", attrs: { id: "heading9" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapse9",
                              "aria-expanded": "true",
                              "aria-controls": "collapse9"
                            }
                          },
                          [_vm._v(" How are the prices generated? ")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapse9",
                          "aria-labelledby": "heading9",
                          "data-parent": "#accordion"
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("p", [
                            _vm._v(
                              " The prices displayed on the DRS WebApp is the BSH Recommended Retail Price (RRP). This means, the price displayed will differ from our retailer partners. The consumer is likely to find the same product for cheaper in the market as we are not competitive as a manufacturer. "
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "help-request box1" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col-lg-6 column mb-lg-0" }, [
                  _c("div", { staticClass: "wrapper" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "33.256",
                          height: "29.504",
                          viewBox: "0 0 33.256 29.504"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "_5",
                              "data-name": "5",
                              transform: "translate(-375.852 -1334.674)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Group_5753",
                                  "data-name": "Group 5753",
                                  transform: "translate(375.997 1334.799)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Path_1735",
                                    "data-name": "Path 1735",
                                    d:
                                      "M406.828,1364.053h-28.7a2.131,2.131,0,0,1-1.848-3.192l14.349-24.992a2.131,2.131,0,0,1,3.7,0l14.349,24.992a2.131,2.131,0,0,1-1.848,3.192Zm-14.349-28.221a1.086,1.086,0,0,0-.952.552l-14.349,24.992a1.1,1.1,0,0,0,.952,1.645h28.7a1.1,1.1,0,0,0,.952-1.645l-14.349-24.992A1.087,1.087,0,0,0,392.479,1335.831Z",
                                    transform: "translate(-375.997 -1334.799)",
                                    fill: "#008ecf",
                                    stroke: "#008ecf",
                                    "stroke-width": "0.25"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Group_5754",
                                  "data-name": "Group 5754",
                                  transform: "translate(391.963 1344.948)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Path_1736",
                                    "data-name": "Path 1736",
                                    d:
                                      "M592.993,1483.3a.516.516,0,0,1-.516-.516v-9.87a.516.516,0,1,1,1.033,0v9.87A.516.516,0,0,1,592.993,1483.3Z",
                                    transform: "translate(-592.477 -1472.396)",
                                    fill: "#008ecf",
                                    stroke: "#008ecf",
                                    "stroke-width": "0.25"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Group_5755",
                                  "data-name": "Group 5755",
                                  transform: "translate(391.628 1357.298)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Path_1737",
                                    "data-name": "Path 1737",
                                    d:
                                      "M588.782,1641.552a.852.852,0,1,1,.852-.852A.853.853,0,0,1,588.782,1641.552Z",
                                    transform: "translate(-587.93 -1639.849)",
                                    fill: "#008ecf",
                                    stroke: "#008ecf",
                                    "stroke-width": "0.25"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c("h6", [_vm._v("Found an issue?")])
                  ])
                ]),
                _c("div", { staticClass: "col-lg-6 text-lg-right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "theme-bg btn btn-primary",
                      attrs: {
                        href: "mailto:gb-iecitsupport@bshg.com",
                        id: "help-request-email-button"
                      }
                    },
                    [_vm._v("Send Help Request")]
                  )
                ]),
                _c("div", { staticClass: "col-lg-12 text-lg-right mt-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "theme-bg btn btn-primary",
                      attrs: {
                        href: "tel:01536436101",
                        id: "call-support-button"
                      }
                    },
                    [_vm._v("Call Support: 01536 436 101")]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }