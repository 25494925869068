<template>
  <BaseLayout>
    <div id="content">
      <div class="container">
        <header class="profile-header">
          <div class="row flex-row-reverse">
            <div class="col-md-6">
              <ul class="details">
                <li>
                  <h6>Last logged in</h6>

                  <span class="time">{{
                    currentUser.last_login | moment("h:mm a")
                  }}</span>
                  <span class="date">{{
                    currentUser.last_login | moment("D/M/YYYY")
                  }}</span>
                </li>
                <li>
                  <h6>App version</h6>
                  <span class="version">V1.0</span>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <h1 class="theme-text">My Profile</h1>
            </div>
          </div>
        </header>
        <div class="row">
          <div class="col-md-6 column mb-md-0">
            <div
              v-if="profileFormMessage"
              class="alert mb-4"
              v-bind:class="profileFormAlertClass"
              role="alert"
            >
              {{ profileFormMessage }}
            </div>
            <ValidationObserver v-slot="{ invalid }">
              <div class="form2">
                <form action="#" @submit.prevent="submitProfileForm" novalidate>
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label for="first-name-field">First Name</label>
                    </div>
                    <div class="col-lg-8">
                      <ValidationProvider
                        name="First Name"
                        rules="required"
                        v-slot="v"
                      >
                        <input
                          id="first-name-field"
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          v-model="currentUser.first_name"
                          v-bind:class="{
                            'is-invalid': v.invalid && v.validated,
                          }"
                        />
                        <div class="invalid-feedback text-left">
                          {{ v.errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label for="surname-field">Surname</label>
                    </div>
                    <div class="col-lg-8">
                      <ValidationProvider
                        name="Surname"
                        rules="required"
                        v-slot="v"
                      >
                        <input
                          id="surname-field"
                          type="text"
                          class="form-control"
                          placeholder="Surname"
                          v-model="currentUser.last_name"
                          v-bind:class="{
                            'is-invalid': v.invalid && v.validated,
                          }"
                        />
                        <div class="invalid-feedback text-left">
                          {{ v.errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label for="email-field">Email Address</label>
                    </div>
                    <div class="col-lg-8">
                      <ValidationProvider
                        name="E-mail"
                        rules="required|email"
                        v-slot="v"
                      >
                        <input
                          id="email-field"
                          type="text"
                          class="form-control"
                          placeholder="Email"
                          v-model="currentUser.email"
                          v-bind:class="{
                            'is-invalid': v.invalid && v.validated,
                          }"
                        />
                        <div class="invalid-feedback text-left">
                          {{ v.errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="text-md-right">
                    <button
                      type="submit"
                      class="theme-bg btn btn-primary"
                      :disabled="invalid || profileFormLoading"
                      id="profile-save-changes-button"

                    >
                      <span
                        v-show="profileFormLoading"
                        class="spinner-grow spinner-grow-sm"
                        style="width: 1.0625rem; height: 1.0625rem"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </ValidationObserver>

            <ValidationObserver v-slot="{ invalid, reset }" ref="passwordForm">
              <div class="form2">
                <form
                  @submit.prevent="submitPasswordForm"
                  @reset.prevent="reset"
                  novalidate
                >
                  <h6>Password Change</h6>
                  <ul class="createria-note list-unstyled">
                    <li>Your password can’t be too similar to your other personal information.</li>
                    <li>Your password must contain at least 8 characters.</li>
                    <li>Your password can’t be a commonly used password.</li>
                    <li>Your password can’t be entirely numeric.</li>
                  </ul>

                  <div
                    v-if="passwordFormMessage"
                    class="alert mb-4"
                    v-bind:class="passwordFormAlertClass"
                    role="alert"
                  >
                    {{ passwordFormMessage }}
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label for="current-password-field"
                        >Current Password</label
                      >
                    </div>
                    <div class="col-lg-8">
                      <ValidationProvider
                        name="Current Password"
                        rules="required"
                        v-slot="v"
                      >
                        <input
                          type="password"
                          class="form-control"
                          id="current-password-field"
                          v-model="passwordFormData.current_password"
                          v-bind:class="{
                            'is-invalid': v.invalid && v.validated,
                          }"
                        />
                        <div class="invalid-feedback text-left">
                          {{ v.errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label for="new-password-field">New Password</label>
                    </div>
                    <div class="col-lg-8">
                      <ValidationProvider
                        name="New Password"
                        rules="required|confirmed:confirmation"
                        v-slot="v"
                      >
                        <input
                          type="password"
                          class="form-control"
                          id="new-password-field"
                          v-model="passwordFormData.new_password"
                          v-bind:class="{
                            'is-invalid': v.invalid && v.validated,
                          }"
                        />
                        <div class="invalid-feedback text-left">
                          {{ v.errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label for="password-confirmation-field"
                        >Confirm Password</label
                      >
                    </div>
                    <div class="col-lg-8">
                      <ValidationProvider
                        name="Password Confirmation"
                        rules="required"
                        v-slot="v"
                        vid="confirmation"
                      >
                        <input
                          type="password"
                          class="form-control"
                          id="password-confirmation-field"
                          v-model="passwordFormData.re_new_password"
                          v-bind:class="{
                            'is-invalid': v.invalid && v.validated,
                          }"
                        />
                        <div class="invalid-feedback text-left">
                          {{ v.errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="text-md-right">
                    <button
                      type="submit"
                      class="theme-bg btn btn-primary"
                      :disabled="invalid || passwordFormLoading"
                      id="profile-update-password-button"
                    >
                      <span
                        v-show="passwordFormLoading"
                        class="spinner-grow spinner-grow-sm"
                        style="width: 1.0625rem; height: 1.0625rem"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Update Password
                    </button>
                  </div>
                </form>
              </div>
            </ValidationObserver>
          </div>
          <div class="col-md-6">
            <div class="accordion-wrapper">
              <h6>FAQs</h6>
              <div class="accordion1" id="accordion">
                
                <div class="card">
                  <div class="card-header" id="heading1">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse1"
                      aria-expanded="true"
                      aria-controls="collapse1"
                      class="collapsed"
                    >
                      How do I compare products?
                    </button>
                  </div>
                  <div
                    id="collapse1"
                    aria-labelledby="heading1"
                    data-parent="#accordion"
                    class="collapse"
                  >
                    <div class="card-body">
                      <p>
                        Simply follow the brand selection on the home page to your desired category to open up the list of products available. Tick the ‘compare’ boxes on the products you wish to compare, then open the Compare page on the right hand side. This will then display your compared products. You can then email this list directly to the customer.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="heading2">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse2"
                      aria-expanded="true"
                      aria-controls="collapse2"
                      class="collapsed"
                    >
                      Can I compare products across multiple brands?
                    </button>
                  </div>

                  <div
                    id="collapse2"
                    class="collapse"
                    aria-labelledby="heading2"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        Yes, once you follow the brand selection on the home page to your desired category, it will open up a list of available products. At the top of this page, you can select multiple brand options including Bosch, NEFF and Siemens. Ticking these boxes will display all products within your chosen category across your selected brands.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="heading3">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse3"
                      aria-expanded="true"
                      aria-controls="collapse3"
                      class="collapsed"
                    >
                     Does the platform offer a direct replacement product?
                    </button>
                  </div>
                  <div
                    id="collapse3"
                    class="collapse"
                    aria-labelledby="heading3"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        No, the platform will not automatically offer a direct replacement product for the customer. It is with your experience and knowledge of the customers’ requirements to use the platform as a tool to offer the best replacement product for them. You will discuss with the customer what is important to them and will select products to suit their needs. 
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="heading4">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse4"
                      aria-expanded="true"
                      aria-controls="collapse4"
                      class="collapsed"
                    >
                     What if I can’t find a product?
                    </button>
                  </div>
                  <div
                    id="collapse4"
                    class="collapse"
                    aria-labelledby="heading4"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        If a product does not appear, this does not mean it’s not available, it may be that the product has been discontinued, however retailers may still stock it, or that it is simply not stored in the system. If you can’t find a product, please notify the Service Desk and we can take a look at adding it in to the application. 
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="heading6">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse6"
                      aria-expanded="true"
                      aria-controls="collapse6"
                      class="collapsed"
                    >
                     How do I use the Quick Search functionality?
                    </button>
                  </div>
                  <div
                    id="collapse6"
                    class="collapse"
                    aria-labelledby="heading6"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        The Quick Search bar can be used in several ways. If you know a specific ENR number, you can enter this and it will search for the product. Or if you want to quickly search for a specific category, such as ‘Dishwashers’, it will display all products under that category. 
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="heading6">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse7"
                      aria-expanded="true"
                      aria-controls="collapse7"
                      class="collapsed"
                    >
                     What do I do if my customer does not receive an email?
                    </button>
                  </div>
                  <div
                    id="collapse7"
                    class="collapse"
                    aria-labelledby="heading7"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        Ensure the customer allows enough time for the email to come through. Please get the customer to check their junk mail. 
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="heading6">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse8"
                      aria-expanded="true"
                      aria-controls="collapse8"
                      class="collapsed"
                    >
                     Who do I contact if I have a problem?
                    </button>
                  </div>
                  <div
                    id="collapse8"
                    class="collapse"
                    aria-labelledby="heading8"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        If you are experiencing any issues with the app, please click the ‘Send Help Request’ button in the My Profile section. This will open an email to send to the Service Desk who will be able to assist. Alternatively you can contact them on gb-ieCITSupport@bshg.com or call them directly at 01536436101.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="heading9">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse9"
                      aria-expanded="true"
                      aria-controls="collapse9"
                      class="collapsed"
                    >
                     How are the prices generated?
                    </button>
                  </div>
                  <div
                    id="collapse9"
                    class="collapse"
                    aria-labelledby="heading9"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        The prices displayed on the DRS WebApp is the BSH Recommended Retail Price (RRP). This means, the price displayed will differ from our retailer partners. The consumer is likely to find the same product for cheaper in the market as we are not competitive as a manufacturer.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="help-request box1">
              <div class="row align-items-center">
                <div class="col-lg-6 column mb-lg-0">
                  <div class="wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33.256"
                      height="29.504"
                      viewBox="0 0 33.256 29.504"
                    >
                      <g
                        id="_5"
                        data-name="5"
                        transform="translate(-375.852 -1334.674)"
                      >
                        <g
                          id="Group_5753"
                          data-name="Group 5753"
                          transform="translate(375.997 1334.799)"
                        >
                          <path
                            id="Path_1735"
                            data-name="Path 1735"
                            d="M406.828,1364.053h-28.7a2.131,2.131,0,0,1-1.848-3.192l14.349-24.992a2.131,2.131,0,0,1,3.7,0l14.349,24.992a2.131,2.131,0,0,1-1.848,3.192Zm-14.349-28.221a1.086,1.086,0,0,0-.952.552l-14.349,24.992a1.1,1.1,0,0,0,.952,1.645h28.7a1.1,1.1,0,0,0,.952-1.645l-14.349-24.992A1.087,1.087,0,0,0,392.479,1335.831Z"
                            transform="translate(-375.997 -1334.799)"
                            fill="#008ecf"
                            stroke="#008ecf"
                            stroke-width="0.25"
                          />
                        </g>
                        <g
                          id="Group_5754"
                          data-name="Group 5754"
                          transform="translate(391.963 1344.948)"
                        >
                          <path
                            id="Path_1736"
                            data-name="Path 1736"
                            d="M592.993,1483.3a.516.516,0,0,1-.516-.516v-9.87a.516.516,0,1,1,1.033,0v9.87A.516.516,0,0,1,592.993,1483.3Z"
                            transform="translate(-592.477 -1472.396)"
                            fill="#008ecf"
                            stroke="#008ecf"
                            stroke-width="0.25"
                          />
                        </g>
                        <g
                          id="Group_5755"
                          data-name="Group 5755"
                          transform="translate(391.628 1357.298)"
                        >
                          <path
                            id="Path_1737"
                            data-name="Path 1737"
                            d="M588.782,1641.552a.852.852,0,1,1,.852-.852A.853.853,0,0,1,588.782,1641.552Z"
                            transform="translate(-587.93 -1639.849)"
                            fill="#008ecf"
                            stroke="#008ecf"
                            stroke-width="0.25"
                          />
                        </g>
                      </g>
                    </svg>

                    <h6>Found an issue?</h6>
                  </div>
                </div>
                <div class="col-lg-6 text-lg-right">
                  <a href="mailto:gb-iecitsupport@bshg.com" class="theme-bg btn btn-primary" id="help-request-email-button"
                    >Send Help Request</a
                  >
                </div>

                <div class="col-lg-12 text-lg-right mt-3"><a href="tel:01536436101" id="call-support-button" class="theme-bg btn btn-primary">Call Support: 01536 436 101</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/base";
import AuthService from "@/services/auth.service";

export default {
  name: "Profile",
  components: {
    BaseLayout,
  },
  data: function () {
    return {
      currentUser: null,
      profileFormMessage: "",
      profileFormLoading: false,
      profileFormAlertClass: "alert-danger",
      passwordFormMessage: "",
      passwordFormLoading: false,
      passwordFormAlertClass: "alert-danger",
      passwordFormData: {
        new_password: "",
        re_new_password: "",
        current_password: "",
      },
    };
  },
  mounted() {
    this.getCurrentUser();
  },
  computed: {},
  methods: {
    getCurrentUser: function () {
      AuthService.currentUser().then((user) => {
        this.currentUser = user;
      });
    },

    submitProfileForm: function () {
      this.profileFormLoading = true;

      AuthService.updateProfile(this.currentUser).then(
        () => {
          this.profileFormLoading = false;
          this.profileFormMessage = "Profile sucessfully updated";
          this.profileFormAlertClass = "alert-success";
        },
        (error) => {
          this.profileFormLoading = false;
          this.profileFormMessage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    submitPasswordForm: function () {
      this.passwordFormLoading = true;

      AuthService.setPassword(this.passwordFormData).then(
        () => {
          this.passwordFormLoading = false;
          this.passwordFormMessage = "Password sucessfully updated";
          this.passwordFormAlertClass = "alert-success";
          this.passwordFormData.new_password = "";
          this.passwordFormData.re_new_password = "";
          this.passwordFormData.current_password = "";
          this.$refs.passwordForm.reset();
        },
        (error) => {
          this.passwordFormLoading = false;
          this.passwordFormMessage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>